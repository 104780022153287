<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="item_search"
			:option="search_option"

			@change="getSearch"
			@click="getSearch"
			@toExcel="toExcel"
			@toItem="toItem"
		>
			<label
				slot="add"
				@click="getSearchSales"
			>
				<v-icon
					v-if="item_search.sales_type"
					class="color-primary"
				>mdi-checkbox-marked</v-icon>
				<v-icon
					v-else
				>mdi-checkbox-blank-outline</v-icon>
				매입몰
			</label>
			<button
				slot="add"
				class="pa-5-10 mr-10 input-box-inline size-px-12"
				@click="onSearchAgency"
			>{{ text_search_agency }}</button>
		</Search>

		<div
			class="mt-10 bg-white pa-10 full-height overflow-y-auto"
		>
			<div v-if="items.length > 0">
				<table class="table table-even">
					<colgroup>
						<col width="80px" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />

						<col width="auto" />
						<col width="auto" />
						<col width="150px" />
						<col width="120px" />
						<col width="100px" />
					</colgroup>
					<thead>
					<tr>
						<th>
							<input
								type="checkbox"
							/>
						</th>
						<th>소속</th>
						<th>구분</th>
						<th>아이디</th>
						<th>이름</th>
						<th>연락처</th>
						<th>가입일</th>
						<th>계정 사용 여부</th>
						<th>일반 상품</th>
						<th>매입 상품</th>
						<th>상세정보</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in item_list"
						:key="item.uid"
						:class="{ 'bg-select': item.uid == item_new.uid }"
					>
						<td>
							<input
								type="checkbox"
							/>
						</td>
						<td>{{ item.agency_upper_name }}</td>
						<td>{{ item.agency_type_name }}</td>
						<td>{{ item.account_id }}</td>
						<td>{{ item.account_name }}</td>
						<td>{{ item.account_phone_number }}</td>
						<td>{{ item.wDate }}</td>
						<td
							class="full-height"
						>
							<div
								class=" flex-row justify-center"
							>
								<v-icon

									class="pa-5"
									:class="item.account_status == 1 ? 'bg-green color-white' : 'btn-default' "
									@click="item.account_status = 1; update(item)"
								>mdi mdi-account-check</v-icon>
								<v-icon

									class="pa-5 "
									:class="item.account_status != 1 ? 'bg-red color-white' : 'btn-default' "
									@click="item.account_status = 0; update(item)"
								>mdi mdi-account-off</v-icon>

								<v-icon

									class="pa-5 bg-red color-white ml-10"
									@click="confirmDelete(item)"
								>mdi mdi-delete-forever</v-icon>
							</div>
						</td>
						<td
							class="full-height"
						>
							<div
								class=" flex-row justify-center"
							>
								<v-icon

									class="pa-5"
									:class="item.sales_status == 1 ? 'bg-green color-white' : 'btn-default' "
									@click="item.sales_status = 1; update(item)"
								>mdi mdi-cart</v-icon>
								<v-icon

									class="pa-5 "
									:class="item.sales_status != 1 ? 'bg-red color-white' : 'btn-default' "
									@click="item.sales_status = 0; update(item)"
								>mdi mdi-cart-off</v-icon>
							</div>
						</td>
						<td
							class="full-height"
						>
							<div
								class=" flex-row justify-center"
							>
								<v-icon

									class="pa-5"
									:class="item.sales_type == 1 ? 'bg-green color-white' : 'btn-default' "
									@click="item.sales_type = 1; update(item)"
								>mdi mdi-cart</v-icon>
								<v-icon

									class="pa-5 "
									:class="item.sales_type != 1 ? 'bg-red color-white' : 'btn-default' "
									@click="item.sales_type = 0; update(item)"
								>mdi mdi-cart-off</v-icon>
							</div>
						</td>
						<td>
							<v-icon
								v-if="item.uid == item_new.uid"
								class="color-red"
								@click="toDetail(item)"
							>mdi mdi-close-box-outline</v-icon>
							<v-icon
								v-else
								@click="toDetail(item)"
								class="color-icon"
							>mdi mdi-arrow-right-bold-box-outline</v-icon>
						</td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:align="'center'"
					:options="item_search"

					class="mt-auto"
					@click="getSearch"
				></Pagination>
			</div>

			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"
		></Excel>

		<AgencySearch
			v-if="is_search_agency"
			agency_type="A001002"

			@cancel="offSearchAgency"
			@click="setSearchAgency"
		></AgencySearch>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Excel from "../../components/Excel";
import Empty from "@/view/Layout/Empty";
import AgencySearch from "./AgencySearch";

export default {
	name: 'AgencyDistributor'
	, components: {AgencySearch, Empty, Excel, Search, Pagination}
	, props: ['Axios', 'rules', 'TOKEN', 'date', 'codes', 'user']
	, data: function (){
		return {
			program: {
				name: '총판 목록'
				,top: true
				,title: true
			}
			,options: {

			}
			, item_search: this.$storage.init({
				page: 1
				, search_type: 'account_id'
				, search_value: ''
				, list_cnt: 10
				, account_status: ''
				, agency_type: ''
				, agency_upper: ''
				, agency_upper_name: ''
				, sales_type: ''
			})
			,search_option:{

				is_item: true
				,is_excel: true
				,is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'account_id'}
					,{ name: '이름', column: 'account_name'}
				]
				,select: [
					{ name: '사용 여부', column: 'account_status', items: [
							{ name: '사용', column: '1'}
							,{ name: '미사용', column: '0'}
						]
					}
				]
			}
			,items: [

			]
			,item_new: {

			}
			,is_item: false
			,is_excel: false
			,excel_data: {
				name: '영업점 목록'
				,header: [
					{ key: 0, name: '구분', column: 'type_name'}
					,{ key: 0, name: '영업점명', column: 'shop_name'}
					,{ key: 0, name: '아이디', column: 'admin_id'}
					,{ key: 0, name: '이름', column: 'admin_name'}
					,{ key: 0, name: '연락처', column: 'admin_tell'}
					,{ key: 0, name: '가입일', column: 'wDate'}
				]
				,content: null
			}
			, items_upper: []
			, is_search_agency: false
		}
	}
	,computed: {
		item_list: function (){

			return this.items.filter((item) => {

				return item
			})
		}
		,btn_name: function (){
			if(this.item_new.uid){
				return '저장'
			}else{
				return '신규 등록'
			}
		}
		, text_search_agency: function(){
			let t = '소속 구분'

			if(this.item_search.agency_upper){
				t = this.item_search.agency_upper_name
			}
			return t
		}
	}
	,methods: {
		save: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSeller'
					,data: this.item_new
				})

				if(result.success){
					await this.getSearch()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,update: async function(item){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putAgencyUpdate'
					,data: item
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				await this.getSearch()
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function (item){
			this.$router.push('/Agency/Detail/' + item.uid)
		}
		, confirmDelete: function(item){
			if(confirm("삭제하시겠습니까?")){
				this.deleteItem(item)
			}
		}
		,deleteItem: async  function(item){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyDelete'
					,data: item
				})

				if(result.success){
					await this.getDistributorList()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,toItem: function (){
			this.$router.push('/agency/item/A001002')
		}
		, getSearchSales: function(){
			this.item_search.sales_type == 1 ? this.item_search.sales_type = 0 : this.item_search.sales_type = 1
			this.getSearch(1)
		}
		,getSearch: function(page){
			if(!page){
				page = 1
			}
			this.item_search.page = page
			this.getDistributorList()
		}
		, getDistributorList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getDistributorList'
					,data: this.item_search
				})
				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.item_search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSearchAgency: function(){
			this.is_search_agency  = true
		}
		, offSearchAgency: function(){
			this.is_search_agency  = false
		}
		, setSearchAgency: function(upper) {
			console.log(upper)
			this.item_search.agency_upper = upper.uid
			this.item_search.agency_upper_name = upper.agency_name
			this.offSearchAgency()
			this.getSearch()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getDistributorList()
	}
}
</script>